import React, {  } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import { slugify } from '../helpers'

export default (props) => {
  const posts = useStaticQuery(graphql`
    query ContentfulArticles {
      allContentfulArticle {
        edges {
          node {
            content {
              childMarkdownRemark {
                html
                timeToRead
              }
            }
            cover {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 512) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            intro
            title
            requirements
            id
          }
        }
      }
    }
  `)

  const articles = posts.allContentfulArticle.edges

  const firstPanel = articles[1] ? articles.filter((article, index) => { return index === articles.length - 1 })[0].node : null
  const secondPanel = articles[2] ? articles.filter((article, index) => { return index ===articles.length - 2 })[0].node : null

  
  return (
    <>
      <div className=" hidden sm:flex md:flex-col lg:flex-row mb-8">
        {firstPanel && <div className="w-full">
          <div className="flex items-center justify-center text-base">
            <div className="p-4 min-w-xs ">
              <Img className="rounded h-56" fluid={firstPanel.cover.localFile.childImageSharp.fluid} />
            </div>
            <div className="p-4 max-w-xs min-w-xs">
              <p className="mb-8 ">{firstPanel.intro}</p>
              <Link to={'/articles/' + slugify(firstPanel.title)}
                className=" text-sm w-64 bg-brand-800 py-2 px-8 rounded text-center text-white font-bold">Read More</Link>
            </div>
          </div>
        </div>}

        {secondPanel && <div className="w-full">
          <div className="flex items-center justify-center text-base">
            <div className="p-4 min-w-xs ">
              <Img className="rounded h-56" fluid={secondPanel.cover.localFile.childImageSharp.fluid} />
            </div>
            <div className="p-4 max-w-xs min-w-xs">
              <p className="mb-8 ">{secondPanel.intro}</p>
              <Link to={'/articles/' + slugify(secondPanel.title)}
                className=" text-sm w-64 bg-brand-800 py-2 px-8 rounded text-center text-white font-bold">Read More</Link>
            </div>
          </div>
        </div>}
      </div>
      <div className="flex flex-wrap items-center justify-center">
        {articles.map(({ node: article }) => (
          <Link to={'/articles/' + slugify(article.title)} key={article.id} className="w-full max-w-xs p-4">
              <div className="">
                <Img className="h-40 rounded" fluid={article.cover.localFile.childImageSharp.fluid} />
              </div>
              <div className="p-4 max-w-xs">
              <p className="mb-8 font-bold">{article.title}</p>
              </div>
          </Link>
        ))}
      </div>
    </>
  )
}
