import React from "react"
// import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Skus from '../components/skus'
import ArticleList from '../components/articleList'
// import Img from "gatsby-image"



import StyledBackgroundSection from "../components/heroImage"

const IndexPage = (props) => {
  return (
    <Layout>
      <SEO title="Home" />
      <div>
        <StyledBackgroundSection />
        <div className="container mx-auto mb-8 mt-12">

          <div className="flex items-center">
            <div className="-ml-6 mr-2">
              <svg width="52" height="19" viewBox="0 0 52 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="52" width="19" height="13" transform="rotate(90 52 0)" fill="#093544" />
                <rect x="33" width="19" height="14" transform="rotate(90 33 0)" fill="#6EAD4D" />
                <rect x="13" width="19" height="13" transform="rotate(90 13 0)" fill="#627C86" />
              </svg>
            </div>
            <p className="font-bold text-gray-700">Shop</p>
          </div>
          <div className="flex flex-wrap py-6">
            <Skus />
          </div>
          <div className="flex items-center">
            <div className="">
              <ArticleList />
            </div>
          </div>
        </div>

      </div>
    </Layout>
  )
}
export default IndexPage
