import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = () => (
  <StaticQuery query={graphql`
      query {
        desktop: file(relativePath: { eq: "hero cover.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage Tag="section"
          fluid={imageData}
          backgroundColor={`#040e18`}
        >
          <div className="hero flex flex-col items-center content-center justify-around text-white relative">

            <div className="max-w-md text-center">
              <div className="text-left ml-4">
                <span className="text-xs text-brand-700 bg-green-400 px-4 py-1 rounded uppercase font-medium">New</span>
              </div>
              <h1 className="font-bold text-5xl text-center leading-tight mb-4">Group Service and Cleaning Kits</h1>
              <p className="text-center text-xl mb-4">Convenient. Affordable. Free shipping.</p>
              {/* <button className="bg-green-500 hover:bg-green-600 rounded text-white text-center px-16 py-2 font-medium">Get yours</button> */}
            </div>
          </div>

        </BackgroundImage>
      )
    }
    }
  />
)

export default BackgroundSection